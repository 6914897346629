import logo from "./logo.svg";
import "./App.css";

function App() {
  return (
    <div className="App">
      <header className="App-header">
        <img
          src={"/earth2.0(icon)-edited.png"}
          className="App-logo"
          alt="logo"
        />
        <button
          class="button-30"
          role="button"
          onClick={() =>
            window.open(
              "https://docsend.com/view/iqn3ztwewdbwcsc8",
              "_blank",
              "noopener,noreferrer"
            )
          }
        >
          HeteroMeta WHITEPAPER
        </button>
      </header>
    </div>
  );
}

export default App;
